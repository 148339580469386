import React from "react"
import ProvidersList from "./ProvidersList"

const Contact = () => {
  return (
    <main className="wrapper contact">
      <h2>Kontakt</h2>
      <ProvidersList />
      <div className="map">
        <iframe
          title='lokalizacja sidziby firmy hydromat'
          className="map-google"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2435.380603455446!2d15.704166215802353!3d52.38165037978829!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x35efbe9bbccac4f4!2sHydraulika%20si%C5%82owa%20-%20Us%C5%82ugi%20%C5%82adowarko-koparki%20-%20Us%C5%82ugi%20spawalnicze%20-%20Hydromat%20Mateusz%20Baran!5e0!3m2!1spl!2spl!4v1624622752500!5m2!1spl!2spl"
          allowFullScreen
          loading="lazy"
        ></iframe>
      </div>
    </main>
  )
}

export default Contact
