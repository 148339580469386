import React, { useContext } from "react"
import { IoCall } from "@react-icons/all-files/io5/IoCall"
import { IoMail } from "@react-icons/all-files/io5/IoMail"
import { ModalVisibility } from "../layout"

const Provider = ({ data }) => {
  const { setModalVisibility } = useContext(ModalVisibility)

  return (
    <div className="provider-item">
      <div className="provider-info">
        <h3 className="provider-name">{data.name}</h3>
        <h4 className="provider-description">{data.description}</h4>
        <a
          aria-label={`zadzwoń pod numer ${data.phone}`}
          className="provider-contact"
          href={`tel:+48${data.phone}`}
        >
          <IoCall /> {data.phone}
        </a>
        <button
          aria-label={`wyślij maila pod adres ${data.phone}`}
          className="provider-contact provider-contact-mail"
          onClick={() => setModalVisibility(true)}
        >
          <IoMail /> {data.email}
        </button>
      </div>
    </div>
  )
}

export default Provider
