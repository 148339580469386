import React from "react"
import Provider from "./Provider"
import { StaticQuery, graphql } from "gatsby"

const ProvidersList = () => {
  return (
    <div className="providers-list">
      <StaticQuery
        query={graphql`
          query {
            hydromat {
              providers {
                id
                name
                description
                phone
                email
              }
            }
          }
        `}
        render={({hydromat: {providers}}) =>
          providers.map(data => <Provider key={data.id} data={data} />)
        }
      />
    </div>
  )
}

export default ProvidersList
